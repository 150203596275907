<template>
  <div>
    <div class="DAV-workshop__trunk">
      <div class="DAV-workshop__trunk--inner">
        <div class="editHeading">
          <h3>
            편집페이지
            <tooltip
              top="30px"
              color="#fff"
              :text="`에디터를 사용하여 편집 할 수 있는 페이지를 선택할 수 있습니다.<br/>편집이 제공되지 않는 쇼핑몰 페이지는 해당 쇼핑몰 솔루션에서 제공되는 템플릿을 사용합니다.`"
            />
          </h3>
        </div>
        <div class="editLink">
          <button class="editLink__page" @click="setPanel('EditablePage')">
            <span>{{ getEditablePage.name }}</span>
          </button>
          <button class="editLink__layout" @click="setPanel('EditableLayout')">
            레이아웃 설정 바로가기
          </button>
        </div>
        <div class="editHeading">
          <h3>
            컴포넌트 편집
            <tooltip
              top="30px"
              color="#fff"
              :text="`<a class='bold' href='${helpUrl}/board-detail?id=11&boardId=1&dtPage=1' target='_blank'>컴포넌트란? ></a><br />
              페이지의 컴포넌트 디자인을 편집할 수 있습니다.<br />
              ･ 컴포넌트명을 클릭하면 해당 컴포넌트 옵션 설정 화면으로 전환됩니다.<br />
              ･ ↑↓ 아이콘 영역을 선택하고 드래그앤드롭 하여 컴포넌트 배치 순서를 변경할 수 있습니다.<br />
              ･ ▶ 아이콘을 클릭하면 컴포넌트 복사, 노출여부, 삭제를 사용할 수 있습니다.<br />
              <a class='bold' href='${helpUrl}/board-detail?id=11&boardId=1&dtPage=1' target='_blank'>컴포넌트 기능 안내 ></a>`"
            />
          </h3>
          <button
            class="editHeading__button ga_e_7"
            @click="componentAddConfirm"
          >
            컴포넌트 추가 <i class="xi-plus-min"></i>
          </button>
        </div>
        <div class="DAV-block">
          <div
            :class="setBlockLayoutClass(key)"
            v-for="(items, key) in componentData"
            :key="key"
          >
            <div class="DAV-block__title" v-if="key == 'header'">
              상단(Header)
            </div>
            <div class="DAV-block__title" v-if="key == 'footer'">
              하단(Footer)
            </div>
            <draggable
              :data-group="getEditablePage.layouts[key]"
              :list="items"
              :group="{name: 'componentData'}"
              delayOnTouchOnly="true"
              ghostClass="DAV-block__item--active"
              handle=".DAV-block__supports--move"
              animation="200"
              @end="componentMove"
              :options="{delay: 100}"
            >
              <div
                class="DAV-block__item"
                :class="
                  setBlockClass(
                    item.displayOnMobile,
                    item.displayOnPc,
                    item.visible
                  )
                "
                v-for="(item, index) in items"
                :key="index"
              >
                <div
                  class="DAV-block__link"
                  @click="passComponentOption(item.index)"
                >
                  <div
                    class="DAV-block__bullet"
                    :class="`DAV-block__bullet--${item.component.icon}`"
                  >
                    <span>컴포넌트 숨김</span>
                  </div>
                  <div class="DAV-block__name">
                    {{ item.name }}
                  </div>
                </div>
                <div class="DAV-block__supports">
                  <button
                    class="DAV-block__supports--move"
                    oncontextmenu="return false"
                  >
                    이동
                  </button>
                  <button
                    class="DAV-block__supports--option"
                    @click="toggleOption(key + index, true)"
                  >
                    컴포넌트 옵션 열기
                  </button>
                </div>
                <transition name="slide" mode="out-in">
                  <div
                    class="DAV-block__options"
                    v-show="optionVisible[key + index]"
                  >
                    <button
                      class="DAV-block__options--delete"
                      @click="componentDelete(item.index, index)"
                    >
                      <span>컴포넌트 삭제</span>
                    </button>
                    <button
                      class="DAV-block__options--copy"
                      @click="componentCopy(item.index, index)"
                    >
                      <span>컴포넌트 복사</span>
                    </button>
                    <button
                      class="DAV-block__options--display"
                      @click="componentHide(item.index)"
                    >
                      <span>컴포넌트 숨김</span>
                    </button>
                    <button
                      class="DAV-block__options--close"
                      @click="toggleOption(key + index, false)"
                    >
                      <span>컴포넌트 옵션 닫기</span>
                    </button>
                  </div>
                </transition>
              </div>
            </draggable>
          </div>
          <button class="DAV-block__add ga_e_8" @click="componentAddConfirm">
            <span>컴포넌트 추가</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");
const AppHelp = process.env.VUE_APP_EXTERNAL_qpick_help_URL;

export default {
  components: {draggable},
  data() {
    return {
      optionVisible: {},
      componentData: {},
      helpUrl: AppHelp
    };
  },
  watch: {
    // 이벤트 감지
    getEvent(val) {
      if (val === "list") {
        this.componentInit();
      }
    }
  },
  computed: {
    ...ModuleTheme.mapGetters(["getThemeLoadData"]),
    ...ModuleEditor.mapGetters(["getDeviceType"]),
    ...ModuleEditorContainer.mapGetters([
      "getEvent",
      "getEditablePage",
      "getComponentLayout",
      "getComponentContainer"
    ])
  },
  methods: {
    // class binding - 컴포넌트 레이아웃
    setBlockLayoutClass(layout) {
      return {
        "DAV-block__wrap DAV-block__wrap--header": layout === "header",
        "DAV-block__wrap DAV-block__wrap--footer": layout === "footer"
      };
    },
    // class binding - 컴포넌트
    setBlockClass(displayOnMobile, displayOnPc, visible) {
      return {
        "DAV-block__item--disabled DAV-block__item--onlypc":
          !displayOnMobile && displayOnPc && this.getDeviceType === "mobile",
        "DAV-block__item--disabled DAV-block__item--onlymobile":
          displayOnMobile && !displayOnPc && this.getDeviceType === "desktop",
        "DAV-block__item--hide": !visible
      };
    },
    // 컴포넌트 옵션으로 이동
    passComponentOption(linkedComponentId) {
      this.setLinkedComponentId(linkedComponentId);
      this.setPanel("ComponentOption");
    },
    // 컴포넌트 설정 toggle
    toggleOption(key, value) {
      this.optionVisible = {};
      this.$set(this.optionVisible, key, value);
    },
    // 연동 컴포넌트 세팅
    componentInit() {
      for (let i in this.getComponentLayout) {
        this.$set(this.componentData, i, []);
        for (let j in this.getComponentLayout[i]) {
          for (let [key, value] of this.getComponentContainer) {
            if (this.getComponentLayout[i][j] === key) {
              this.componentData[i].push(value);
            }
          }
        }
      }
    },
    // 연동 컴포넌트 정렬
    componentMove() {
      let componentLayout = {};

      for (let i in this.componentData) {
        let arr = [];
        for (let data of this.componentData[i]) {
          data.linkedComponentGroupId = this.getEditablePage.layouts[i];
          arr.push(data.index);
        }
        componentLayout[i] = arr;
      }

      this.setComponentLayout(componentLayout);
      this.setEvent("move");
      this.setAttach(true);
    },
    // 연동 컴포넌트 숨김
    componentHide(id) {
      this.optionVisible = {};

      this.getComponentContainer.get(id).visible =
        !this.getComponentContainer.get(id).visible;

      this.setLinkedComponentId(id);
      this.setEvent("display");
      this.setAttach(true);
    },
    // 연동 컴포넌트 삭제
    componentDelete(id, i) {
      this.optionVisible = {};

      let layout = this.checkComponentLayout(id);
      this.getComponentLayout[layout].splice(i, 1);

      this.getComponentContainer.delete(id);

      this.componentInit();
      this.setComponentLayout(this.getComponentLayout);
      this.setComponentContainer(this.getComponentContainer);
      this.setDeleteItems(id);
      this.setEvent("remove");
      this.setAttach(true);
    },
    // 연동 컴포넌트 복사
    componentCopy(id, i) {
      const count =
        this.componentData.content.length +
        this.componentData.footer.length +
        this.componentData.header.length;
      if (count >= 50) {
        this.setAlert({
          isVisible: true,
          message: `컴포넌트는 최대 50개까지 추가할 수 있습니다.`
        });
        return;
      }
      this.optionVisible = {};

      // getComponentContainer 가장 큰 index 키 찾아서 추가
      const keys = [...this.getComponentContainer.keys()];
      const maxValue = Math.max(...keys);

      let layout = this.checkComponentLayout(id);
      this.getComponentLayout[layout].splice(++i, 0, maxValue + 1);

      let originItem = JSON.stringify(this.getComponentContainer.get(id));
      let copyItem = JSON.parse(originItem);
      copyItem.id = null;
      copyItem.index = maxValue + 1;
      if (copyItem.name.indexOf(" (copy)") < 0) {
        copyItem.name += " (copy)";
      }
      this.getComponentContainer.set(maxValue + 1, copyItem);

      this.componentInit();
      this.setComponentContainer(this.getComponentContainer);
      this.setEvent("copy");
      this.setAttach(true);
    },
    // 컴포넌트 레이아웃 체크
    checkComponentLayout(id) {
      if (this.getComponentLayout.header.find(item => item == id)) {
        return "header";
      }
      if (this.getComponentLayout.content.find(item => item == id)) {
        return "content";
      }
      if (this.getComponentLayout.footer.find(item => item == id)) {
        return "footer";
      }
    },
    // 컴포넌트 추가 confirm
    componentAddConfirm() {
      const count =
        this.componentData.content.length +
        this.componentData.footer.length +
        this.componentData.header.length;
      if (count >= 50) {
        this.setAlert({
          isVisible: true,
          message: `컴포넌트는 최대 50개까지 추가할 수 있습니다.`
        });
        return;
      }
      this.setPanel("ComponentClass");
    },
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleEditor.mapMutations([
      "setLinkedComponentId",
      "setPanel",
      "setAttach",
      "setDeleteItems"
    ]),
    ...ModuleEditorContainer.mapMutations([
      "setEvent",
      "setComponentLayout",
      "setComponentContainer"
    ])
  },
  mounted() {
    this.componentInit();
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__trunk {
  overflow-y: auto;

  &--inner {
    position: relative;
    padding: 0 16px 16px;
    background-color: #f7f9fc;

    &::after {
      content: "";
      z-index: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 178px;
      background-color: #54c7a2;
    }
  }
}
.editHeading {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
  h3 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
  }
  &__help {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    background-image: url($path + "ico_help_green.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__button {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 32px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
  }
}
.editLink {
  z-index: 1;
  position: relative;
  margin-top: 4px;

  &__page {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 32px 0 16px;
    border-radius: 8px;
    background-color: #ffffff;
    background-image: url($path + "bul_arr_next.svg");
    background-repeat: no-repeat;
    background-position: right 17px center;

    span {
      font-weight: 500;
      @include text-ellipsis;
    }
  }
  &__layout {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #ffffff;
    background-image: url($path + "ico_layout_setting.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
}
.DAV-block {
  z-index: 1;
  position: relative;
  margin-top: 8px;
  padding-bottom: 24px;

  &__title {
    height: 56px;
    line-height: 56px;
    padding: 0 16px;
    color: #309574;
    font-size: 14px;
    font-weight: 700;
  }
  &__link {
    display: flex;
    width: calc(100% - 81px);
    cursor: pointer;
    align-items: center;
    overflow: hidden;
  }
  &__name {
    flex-grow: 1;
    text-align: left;
    padding-right: 10px;
    @include text-ellipsis;
  }
}
.DAV-block__wrap {
  border: 1px solid #54c7a2;
  @include box;

  &--header {
    margin-bottom: 16px;
  }
  &--footer {
    margin-top: 16px;
  }
  .DAV-block__item {
    margin-top: 0;
    border: 0;
    box-shadow: none;

    &::after {
      content: "";
      position: absolute;
      right: 8px;
      bottom: 0;
      left: 8px;
      border-bottom: 1px solid #f0f0f0;
    }
    &:last-child::after {
      border-bottom: 0;
    }
    &:first-child {
      margin-top: -12px !important;
    }
  }
  // .DAV-block__item + .DAV-block__item--disabled {
  //   margin-top: -1px;
  // }
  .DAV-block__item--disabled {
    border-radius: 0;
  }
  .DAV-block__item--disabled:last-child {
    border-radius: 0 0 8px 8px;
  }
}
.DAV-block__item {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  margin-top: 8px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  @include box;

  &--active {
    z-index: 1 !important;
    border: 0 !important;
    box-shadow: 0px 0px 8px #54c7a2 !important;

    & .DAV-block__supports--move {
      background-image: url($path + "ico_move_active.svg");
    }
  }
  &--hide {
    .DAV-block__bullet span {
      display: block;
    }
    .DAV-block__options--display span {
      background-image: url($path + "ico_opt_display_show.svg");
    }
  }
  &--disabled {
    background: #f2eef8;
    border: 1px solid #d6d0df;

    .DAV-block__bullet {
      background-color: #b08de1;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    &.DAV-block__item--onlypc .DAV-block__bullet {
      background-image: url($path + "ico_opt_pc.svg");
    }
    &.DAV-block__item--onlymobile .DAV-block__bullet {
      background-image: url($path + "ico_opt_mobile.svg");
    }
    &.DAV-block__item--active {
      box-shadow: 0px 0px 8px #936be8 !important;
    }
    &.DAV-block__item--active .DAV-block__supports--move {
      background-image: url($path + "ico_move_disabled.svg");
    }
  }
}
.DAV-block__bullet {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 16px;
  background-color: #d2f8ec;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 12px;

  span {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: rgba(63, 63, 63, 0.4);
    background-image: url($path + "ico_opt_display_hide.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 16px auto;
    text-indent: -9999px;
  }
  &--category {
    background-image: url($path + "bul_component_category.svg");
  }
  &--footer {
    background-image: url($path + "bul_component_footer.svg");
  }
  &--header {
    background-image: url($path + "bul_component_header.svg");
  }
  &--html {
    background-image: url($path + "bul_component_html.svg");
  }
  &--image {
    background-image: url($path + "bul_component_image.svg");
  }
  &--plugin {
    background-image: url($path + "bul_component_plugin.svg");
  }
  &--product {
    background-image: url($path + "bul_component_product.svg");
  }
  &--solution {
    background-image: url($path + "bul_component_solution.svg");
  }
  &--text {
    background-image: url($path + "bul_component_text.svg");
  }
}
.DAV-block__supports {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: auto;
  width: 81px;
  height: 24px;
  background-image: url($path + "bg_vline.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;

  &--move {
    width: 24px;
    height: 24px;
    background-image: url($path + "ico_move.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
    cursor: pointer;
    -webkit-touch-callout: none;
  }
  &--option {
    width: 24px;
    height: 24px;
    background-image: url($path + "bul_arr_next.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
}
.DAV-block__options {
  z-index: 1;
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 72px;
  background-color: #985fe8;
  border-radius: 8px;

  button {
    float: left;
    height: 100%;
    background-image: url($path + "bg_vline_purple.svg");
    background-repeat: no-repeat;
    background-position: 0 50%;
    span {
      display: block;
      height: 100%;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    &:first-child,
    &:last-child {
      background: 0;
    }
  }
  &--delete {
    width: 26%;

    span {
      background-image: url($path + "ico_opt_delete.svg");
    }
  }
  &--copy {
    width: 26%;

    span {
      background-image: url($path + "ico_opt_copy.svg");
    }
  }
  &--display {
    width: 26%;

    span {
      background-image: url($path + "ico_opt_display_hide.svg");
    }
  }
  &--close {
    width: 22%;

    span {
      background-color: #484948;
      background-image: url($path + "ico_opt_close.svg");
      border-radius: 0px 8px 8px 0px;
    }
  }
}
.DAV-block__add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  margin-top: 24px;
  border: 2px dashed #d8dae5;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #f7f9fc;

  span {
    padding-right: 27px;
    color: #797979;
    font-size: 16px;
    background-image: url($path + "ico_file.svg");
    background-repeat: no-repeat;
    background-position: 100% 50%;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-workshop__trunk--inner {
    padding-top: 20px;
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
}
</style>
