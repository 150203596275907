var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('div', {
    staticClass: "DAV-workshop__trunk--inner"
  }, [_c('div', {
    staticClass: "editHeading"
  }, [_c('h3', [_vm._v(" 편집페이지 "), _c('tooltip', {
    attrs: {
      "top": "30px",
      "color": "#fff",
      "text": "\uC5D0\uB514\uD130\uB97C \uC0AC\uC6A9\uD558\uC5EC \uD3B8\uC9D1 \uD560 \uC218 \uC788\uB294 \uD398\uC774\uC9C0\uB97C \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\uD3B8\uC9D1\uC774 \uC81C\uACF5\uB418\uC9C0 \uC54A\uB294 \uC1FC\uD551\uBAB0 \uD398\uC774\uC9C0\uB294 \uD574\uB2F9 \uC1FC\uD551\uBAB0 \uC194\uB8E8\uC158\uC5D0\uC11C \uC81C\uACF5\uB418\uB294 \uD15C\uD50C\uB9BF\uC744 \uC0AC\uC6A9\uD569\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "editLink"
  }, [_c('button', {
    staticClass: "editLink__page",
    on: {
      "click": function click($event) {
        return _vm.setPanel('EditablePage');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.getEditablePage.name))])]), _c('button', {
    staticClass: "editLink__layout",
    on: {
      "click": function click($event) {
        return _vm.setPanel('EditableLayout');
      }
    }
  }, [_vm._v(" 레이아웃 설정 바로가기 ")])]), _c('div', {
    staticClass: "editHeading"
  }, [_c('h3', [_vm._v(" 컴포넌트 편집 "), _c('tooltip', {
    attrs: {
      "top": "30px",
      "color": "#fff",
      "text": "<a class='bold' href='".concat(_vm.helpUrl, "/board-detail?id=11&boardId=1&dtPage=1' target='_blank'>\uCEF4\uD3EC\uB10C\uD2B8\uB780? ></a><br />\n            \uD398\uC774\uC9C0\uC758 \uCEF4\uD3EC\uB10C\uD2B8 \uB514\uC790\uC778\uC744 \uD3B8\uC9D1\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br />\n            \uFF65 \uCEF4\uD3EC\uB10C\uD2B8\uBA85\uC744 \uD074\uB9AD\uD558\uBA74 \uD574\uB2F9 \uCEF4\uD3EC\uB10C\uD2B8 \uC635\uC158 \uC124\uC815 \uD654\uBA74\uC73C\uB85C \uC804\uD658\uB429\uB2C8\uB2E4.<br />\n            \uFF65 \u2191\u2193 \uC544\uC774\uCF58 \uC601\uC5ED\uC744 \uC120\uD0DD\uD558\uACE0 \uB4DC\uB798\uADF8\uC564\uB4DC\uB86D \uD558\uC5EC \uCEF4\uD3EC\uB10C\uD2B8 \uBC30\uCE58 \uC21C\uC11C\uB97C \uBCC0\uACBD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br />\n            \uFF65 \u25B6 \uC544\uC774\uCF58\uC744 \uD074\uB9AD\uD558\uBA74 \uCEF4\uD3EC\uB10C\uD2B8 \uBCF5\uC0AC, \uB178\uCD9C\uC5EC\uBD80, \uC0AD\uC81C\uB97C \uC0AC\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br />\n            <a class='bold' href='").concat(_vm.helpUrl, "/board-detail?id=11&boardId=1&dtPage=1' target='_blank'>\uCEF4\uD3EC\uB10C\uD2B8 \uAE30\uB2A5 \uC548\uB0B4 ></a>")
    }
  })], 1), _c('button', {
    staticClass: "editHeading__button ga_e_7",
    on: {
      "click": _vm.componentAddConfirm
    }
  }, [_vm._v(" 컴포넌트 추가 "), _c('i', {
    staticClass: "xi-plus-min"
  })])]), _c('div', {
    staticClass: "DAV-block"
  }, [_vm._l(_vm.componentData, function (items, key) {
    return _c('div', {
      key: key,
      class: _vm.setBlockLayoutClass(key)
    }, [key == 'header' ? _c('div', {
      staticClass: "DAV-block__title"
    }, [_vm._v(" 상단(Header) ")]) : _vm._e(), key == 'footer' ? _c('div', {
      staticClass: "DAV-block__title"
    }, [_vm._v(" 하단(Footer) ")]) : _vm._e(), _c('draggable', {
      attrs: {
        "data-group": _vm.getEditablePage.layouts[key],
        "list": items,
        "group": {
          name: 'componentData'
        },
        "delayOnTouchOnly": "true",
        "ghostClass": "DAV-block__item--active",
        "handle": ".DAV-block__supports--move",
        "animation": "200",
        "options": {
          delay: 100
        }
      },
      on: {
        "end": _vm.componentMove
      }
    }, _vm._l(items, function (item, index) {
      return _c('div', {
        key: index,
        staticClass: "DAV-block__item",
        class: _vm.setBlockClass(item.displayOnMobile, item.displayOnPc, item.visible)
      }, [_c('div', {
        staticClass: "DAV-block__link",
        on: {
          "click": function click($event) {
            return _vm.passComponentOption(item.index);
          }
        }
      }, [_c('div', {
        staticClass: "DAV-block__bullet",
        class: "DAV-block__bullet--".concat(item.component.icon)
      }, [_c('span', [_vm._v("컴포넌트 숨김")])]), _c('div', {
        staticClass: "DAV-block__name"
      }, [_vm._v(" " + _vm._s(item.name) + " ")])]), _c('div', {
        staticClass: "DAV-block__supports"
      }, [_c('button', {
        staticClass: "DAV-block__supports--move",
        attrs: {
          "oncontextmenu": "return false"
        }
      }, [_vm._v(" 이동 ")]), _c('button', {
        staticClass: "DAV-block__supports--option",
        on: {
          "click": function click($event) {
            return _vm.toggleOption(key + index, true);
          }
        }
      }, [_vm._v(" 컴포넌트 옵션 열기 ")])]), _c('transition', {
        attrs: {
          "name": "slide",
          "mode": "out-in"
        }
      }, [_c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.optionVisible[key + index],
          expression: "optionVisible[key + index]"
        }],
        staticClass: "DAV-block__options"
      }, [_c('button', {
        staticClass: "DAV-block__options--delete",
        on: {
          "click": function click($event) {
            return _vm.componentDelete(item.index, index);
          }
        }
      }, [_c('span', [_vm._v("컴포넌트 삭제")])]), _c('button', {
        staticClass: "DAV-block__options--copy",
        on: {
          "click": function click($event) {
            return _vm.componentCopy(item.index, index);
          }
        }
      }, [_c('span', [_vm._v("컴포넌트 복사")])]), _c('button', {
        staticClass: "DAV-block__options--display",
        on: {
          "click": function click($event) {
            return _vm.componentHide(item.index);
          }
        }
      }, [_c('span', [_vm._v("컴포넌트 숨김")])]), _c('button', {
        staticClass: "DAV-block__options--close",
        on: {
          "click": function click($event) {
            return _vm.toggleOption(key + index, false);
          }
        }
      }, [_c('span', [_vm._v("컴포넌트 옵션 닫기")])])])])], 1);
    }), 0)], 1);
  }), _c('button', {
    staticClass: "DAV-block__add ga_e_8",
    on: {
      "click": _vm.componentAddConfirm
    }
  }, [_c('span', [_vm._v("컴포넌트 추가")])])], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }